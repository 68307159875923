const messages_en = {
	'login.welcome': 'Welcome, sign up',
	'logout.title': 'Logout',

	'commons.insurer': 'Insurer',
	'commons.reinsurer': 'Reinsurer',

	'commons.back': 'Back',
	'commons.save': 'Save',
	'commons.validate': 'Validate',
	'commons.update': 'Update',
	'commons.rowsPerPage': 'Rows per page',
	'commons.of': 'of',
	'commons.noData': 'Data not found',
	'commons.step': 'Step',
	'commons.filter': 'Filter...',
	'commons.dragAndDrop': 'Click or drag and drop the file here',
	'commons.findNumber': 'Find number',
	'commons.initDate': 'Init date',
	'commons.endDate': 'End date',
	'commons.downloadAll': 'Download all',
	'commons.consolidated': 'Consolidated',
	'commons.status': 'Status',
	'commons.apply': 'Apply',
	'commons.credits': 'Credits',
	'commons.next': 'Next',
	'commons.download': 'Download',
	'commons.have': 'You have',
	'commons.error': 'error(s)',
	'commons.required': 'Required field',
	'commons.textQuantity': 'Number of characters:',
	'commons.start': 'Start',
	'commons.daily': 'Daily',
	'commons.weekly': 'Weekly',
	'commons.biweekly': 'Biweekly',
	'commons.monthly': 'Monthly',
	'commons.company_supervisor': 'Company supervisor',
	'commons.customer': 'Customer',
	'commons.downloading': 'Downloading',
	'commons.root': 'root',
	'commons.add': 'Add',
	'commons.yes': 'Yes',
	'commons.contactUs': 'Contact us',
	'commons.suscribeMe': 'Subscribe',
	'commons.goToPay': 'Go to pay',
	'commons.mxn': 'MXN',

	'month.enero': 'January',
	'month.febrero': 'February',
	'month.marzo': 'March',
	'month.abril': 'April',
	'month.mayo': 'May',
	'month.junio': 'June',
	'month.julio': 'July',
	'month.agosto': 'August',
	'month.septiembre': 'September',
	'month.octubre': 'October',
	'month.noviembre': 'November',
	'month.diciembre': 'December',

	'table.name': 'Name',
	'table.email': 'Email',
	'table.phone': 'Phone',
	'table.membership': 'Membership',
	'table.createdAt': 'Member since',
	'table.membershipType': 'Membership type',
	'table.police': 'Police',
	'table.options': 'Options',
	'table.seller': 'Wilma guide',
	'table.status': 'Status',
	'table.actions': 'Actions',
	'table.date': 'Date',
	'table.memberId': 'Member ID',
	'table.guideId': 'Guide ID',
	'table.allyId': 'Ally ID',
	'table.ally': 'Ally',
	'table.discount': 'Discount',
	'table.company': 'Company',
	'table.insuredAmount': 'Insured amount',
	'table.premiumAmount': 'Total premium amount',
	'table.city': 'City',
	'table.premium': 'Premium',
	'table.folio': 'Folio',

	'opt.delete': 'Delete',
	'opt.edit': 'Edit',
	'opt.credits': 'Add credits',
	'opt.downloadReport': 'Download report',
	'opt.downloadAudio': 'Download audio',
	'opt.downloadFile': 'Download file',
	'opt.clone': 'Clone campaign',
	'opt.moreThan100': 'More than 100',
	'opt.to': 'to',
	'opt.businessLine.1': 'Lodging/Restaurants and Events',
	'opt.businessLine.2': 'Transportation',
	'opt.businessLine.3': 'Tour Operator and Experience Provider',
	'opt.businessLine.4': 'Beach Services',
	'opt.businessLine.5': 'Vacation Property Rentals',
	'opt.businessLine.6': 'Real Estate and Property Management',
	'opt.businessLine.7': 'Culture',
	'opt.businessLine.8': 'Health Services',
	'opt.businessLine.9': 'Consulting',
	'opt.businessLine.10': 'Commercial spaces',
	'opt.businessLine.12': 'Residential property',
	'opt.businessLine.11': 'Others',
	'opt.customized': 'customized',

	'dashboard.title': 'Dashboard',
	'dashboard.members': 'Members',
	'dashboard.addMember': 'Add member',
	'dashboard.sellers': 'Wilma guides',
	'dashboard.addGuide': 'Add guide',
	'dashboard.memberships': 'Memberships',

	'police.limitText': 'Maximum Limit of Indemnity',
	'police.premium': 'Premium',
	'police.expeditionAmount': 'Expedition expenses',
	'police.iva': 'VAT',
	'police.resume': 'Membership summary',
	'police.contractor': 'Policyholder',

	'forms.loginTitle': 'Login',
	'forms.login': 'Login',
	'forms.welcomeMessage': 'Log in to resume your applications or manage your memberships.',
	'forms.userName': 'User name',
	'forms.userNameLabel': 'Enter your user name',
	'forms.name': 'Name',
	'forms.nameLabel': 'Enter your name',
	'forms.lastName': 'Last name',
	'forms.lastNameLabel': 'Enter your last name',
	'forms.gender': 'Select your gender',
	'forms.age': 'Age',
	'forms.ageLabel': 'Enter your age',
	'forms.whatsapp': 'WhatsApp number',
	'forms.whatsappLabel': 'Enter your WhatsApp number',
	'forms.email': 'Email',
	'forms.emailLabel': 'Enter your email',
	'forms.password': 'Password',
	'forms.passwordLabel': 'Enter your password',
	'forms.confirmPassword': 'Confirm your password',
	'forms.forgetPassword': 'Forgot your password?',
	'forms.wilmaGuide': 'Wilma Guide (optional)',
	'forms.wilmaGuideLabel': 'Enter your Wilma guide code',
	'forms.register': 'Register',
	'forms.newUser': 'New user',
	'forms.tellUs': 'Tell us more about yourself',
	'forms.nameCorporate': 'Name or corporate name',
	'forms.contractor': 'Contracting Party',
	'forms.contractorLabel': 'Enter the name of the contracting party',
	'forms.streetAndNumber': 'Street and number',
	'forms.streetAndNumberLabel': 'Enter your street and number',
	'forms.state': 'State',
	'forms.companyCity': 'Population/municipality',
	'forms.companyCityLabel': 'Enter your population/municipality',
	'forms.colony': 'Neighborhood',
	'forms.colonyLabel': 'Enter your neighborhood',
	'forms.zipCode': 'Zip Code',
	'forms.zipCodeLabel': 'Enter your zip code',
	'forms.rfc': 'Passport Number',
	'forms.rfcLabel': 'Enter your passport number',
	'forms.activity': 'Activity',
	'forms.businessLine': 'Select your activity',
	'forms.commercialName': 'Business name',
	'forms.commercialNameLabel': 'Enter your legal business name',
	'forms.numberOfEmployees': 'Number of employees',
	'forms.largeRevenue': 'Is your annual turnover revenue greater than $235,000,000 MXN?',
	'forms.largeRevenueM1':
		'Our membership is focused on helping the SME sector, please contact one of our consultants for a tailor-made solution.',
	'forms.largeRevenueM2':
		'If you are interested in acquiring one of our memberships, please contact us for more information.',
	'forms.chooseMembership': 'Choose one of our memberships',
	'forms.selectCity': 'Select a city',
	'forms.selectMembership': 'Select a type of membership',
	'forms.compensationLimit': 'Maximum Limit of Indemnity',
	'forms.compensationLimitLabel': 'Maximum Limit of indemnity of your membership',
	'forms.recover': 'Recover',

	'errors.invalidEmail': 'Must be a valid email',
	'errors.invalidPassword': 'Password must contain at least 6 characters',
	'errors.required': 'This field is required',
	'errors.minimun': 'The minimum amount is $100,000',
	'errors.maximum': 'The maximum amount is $7,000,000',

	'label.male': 'Male',
	'label.female': 'Female',
	'label.other': 'I prefer not to say',

	'msg.termsAndConditions':
		'I confirm, under oath, that the data is correct and that the Contracting Party has assets or an economic activity that may be affected by a hurricane within the selected Coverage Area.',
	'msg.forgotPasswordTitle': 'Recover your password',
	'msg.forgotPassword': 'Enter your email and we will send you a link to recover your password.',
	'msg.newUserTitle': 'Join the Wilma community!',
	'msg.newUser': 'Enter the following data',
	'msg.shortTerms': 'I accept the terms and conditions',
	'msg.seeTerms': 'See terms and conditions',

	'account.title': 'My account',
	'account.membership': 'My memberships',
	'account.noMembership': 'You do not have active memberships',
	'account.completeProcess': 'Complete process',
	'account.noProcess': 'You do not have incomplete processes',
	'account.welcome': 'Welcome',
	'account.validFrom': 'Valid from',
	'account.validUntil': 'Valid until',
	'account.policy': 'Policy',

	'members.title': 'Members',

	'guides.title': 'Wilma guides',

	'memberships.title': 'Memberships',

	'allies.title': 'Allies',

	'quotes.title': 'Quotes',

	'endSeason.p1':
		'THE 2024 SEASON HAS ENDED. WE WILL SOON HAVE THE PRICING FOR THE 2025 SEASON, AND YOU WILL BE THE FIRST TO KNOW. TOGETHER FOR A PREPARED COMMUNITY.',
	'endSeason.p2': 'TOGETHER FOR A PREPARED COMMUNITY.',
	'endSeason.button': 'Back',
	'endSeason.p3': 'The 2024 hurricane season has been a complete success.',
	'endSeason.p4':
		'As of <strong>SEPTEMBER 15, THE 2024 SEASON IS CLOSED</strong>, meaning new memberships cannot be purchased. In March 2025, we will have the pricing for the upcoming season, and our Wilma Guides will be the first to know about it.',
};
export default messages_en;
